@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  line-height: normal;
}

input,
textarea {
  font-size: revert;
  font-weight: revert;
}
img,
video {
  max-width: unset;
}

html,
body {
  max-width: 100vw;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-size: revert;
  font-weight: revert;
}

.kdot-container {
  width: 100%;
  padding: 0 calc(50vw - 500px);
  @media screen and (max-width: 1200px) {
    padding: 0 10%;
  }
  @media screen and (max-width: 768px) {
    padding: 0 6%;
  }
}

.kdotdev-container {
  width: 100%;
  padding: 24px calc(50vw - 550px);

  @media screen and (max-width: 1200px) {
    padding: 24px calc(50vw - 400px);
  }
  @media screen and (max-width: 820px) {
    padding: 24px 6%;
  }
}
.article-container {
  @apply kdot-container lg:!px-[calc(50vw_-_450px)] xlp:!px-[calc(50vw_-_700px)];
}

.react-tweet-theme img {
  margin: 0px !important;
}

.znc .react-tweet-theme a {
  color: inherit;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hljs {
  background-color: white !important;
}
